<template>
  <ProfileTap :selected="'/subscriptions'"></ProfileTap>

  <v-container>

    <individual class="my-11"/>
    <institution class="my-11"/>
  </v-container>
</template>
<script>
import individual from './individual.vue';
import institution from './institution.vue';
import ProfileTap from "@/views/ProfileTap.vue";
export default {
  components:{
    ProfileTap,
    individual,
    institution
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
