<template>
  <ProfileTap :selected="'/commission'"></ProfileTap>

  <v-container>

    <v-card class="pa-xs-5 px-2 card_update" elevation="0">
      <v-text-field
        density="compact"
        variant="solo"
        single-line
        hide-details
        :placeholder="$t('Application_commission')"
        class="rounded my-6 border-style font-weight-medium"
        v-model="amount"
      >
      </v-text-field>

      <div class="mt-md-16 mt-3">
        <button
          class="previewads rounded d-block py-2 my-16"
          style="width: 65%"
          @click="Commission"
        >
          {{ $t("Payment_commission") }}
        </button>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";

export default {
  components: {ProfileTap},
  data() {
    return {
     amount: '',
    };
  },
  methods: {
    async Commission() {     
      let response = await crudDataService.create(`donate`,{amount:this.amount});
      // console.log(response.data.data);
    },
  },
  mounted() {},
};
</script>
<style></style>
