<template>
  <ProfileTap :selected="'/followers'"></ProfileTap>

  <v-container v-if="items.length >= 1">


    <div class="d-flex flex-wrap justify-start pt-7">
      <v-row class="text-center">
        <v-col cols="12" md="4" sm="6" v-for="(item, i) in items" :key="i">
          <img :src="item.image" class="rounded-circle" style="height: 190px" />
          <p
            style="font-size: 20px"
            class="font-weight-medium text-center my-2"
          >
            {{ item.name }}
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container v-else-if="items.length == 0" class="my-12 py-10">
    <v-alert
      variant="outlined"
      type="warning"
      prominent
      border="top"
      class="mt-12 mb-12"
    >
      {{ $t("There_is_no_data") }}
    </v-alert>
  </v-container>

  <v-container v-else>
    <v-row>
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";

export default {
  components: {ProfileTap},
  data() {
    return {
      items: [],
      texts: [1, 2, 3, 4, 5],
    };
  },
  methods: {
    async followers() {
      let response = await crudDataService.getAll(`user-followers`);
      this.items = response.data.data;
    },
  },
  mounted() {
    this.followers();
  },
};
</script>
<style></style>
