import http from "@/http-common";
import httpWithToken from "@/http-common";

class crudDataService {
  getAll(table_name, apply_pagination = true) {
    if (apply_pagination == true) {
      return http.get(`/${table_name}`);
    } else {
      return http.get(`/${table_name}`);
    }
  }

  get(table_name, id) {
    return http.get(`/${table_name}/${id}`);
  }
  search(table_name, term) {
    return http.get(`/${table_name}${term}`);
  }
  create(table_name, data, headers) {
    return http.post(`/${table_name}`, data, headers);
  }

  update(table_name, id, data) {
    return http.patch(`/${table_name}/${id}`, data);
  }

  delete(table_name, id) {
    return http.delete(`/${table_name}/${id}`);
  }

  deleteAll(table_name) {
    return http.delete(`/${table_name}`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }

  filter(table_name, about, params) {
    const queryParams = new URLSearchParams(params).toString();
    return http.get(`/${table_name}/${about}?${queryParams}`);
  }
  getsuport(table_name, params) {
    const queryParams = new URLSearchParams(params).toString();
    return http.get(`/${table_name}?${queryParams}`);
  }
  get_request(url) {
    return http.get(`/${url}`);
  }
}

export default new crudDataService();
