<template>
  <ProfileTap :selected="'/my-profits'"></ProfileTap>
  <v-row>
    <v-col md="3" style="">
      <section v-if="items" class="profits-list">
        <v-row v-for="item in items " class="align-center  profits-item" :class="{page_active :item.is_active}" @click="getPage(item.route)">
          <div class="content-div">
            <v-icon :icon="item.icon"></v-icon>
            <h6 class="font-weight-bold text-h6" style="margin: auto 10px;">
              {{ item.title }} {{item.icon === "mdi-account-group-outline"? '('+ count_user +')':''}}
            </h6>
          </div>
        </v-row>

      </section>
    </v-col>
    <v-col md="9">
      <v-row>


      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";
import walletIcon from "../../assets/W13.png";
import {useAuth} from "@/axios";


export default {
  components: {ProfileTap},
  data() {
    return {
      walletIcon:walletIcon,
      items: [
      { title: this.$t("MyProfits"), route: "/my-profits" ,is_active:true,icon:"mdi-wallet"},
      { title: this.$t("users_SUb"), route: "/my-profits/users",is_active:false,icon:"mdi-account-group-outline"},
      { title: this.$t("transactions"), route: "/my-profits/transactions" ,is_active:false,icon:"mdi-cash-multiple"}


    ],
      text_copied:"",
      data_profit: "",
      count_user: 0,
      message: "",
      copied: false,
    };
  },

  methods: {

    async getPage(page_route) {
      this.$router.push(page_route);
    },
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.text_copied);
        this.copied = true;
        setTimeout(() => (this.copied = false), 1000);
      } catch (err) {
        console.error(this.$t('Copy failed')+":", err);
      }
    },
    async shareText() {
      if (navigator.share) {
        try {
          await navigator.share({
            text: this.$t('share_text',{code:this.text_copied}),
          });

          this.shared = true;
          setTimeout(() => (this.shared = false), 2000);
        } catch (err) {
          console.error(this.$t('Sharing failed')+"❌:", err);
        }
      } else {
        alert(this.$t('The browser does not support text sharing.')+"❌ ");
      }
    },

    async userProfit() {
      try {
        let response=  await crudDataService.getAll("marketers/get-profile")
        this.data_profit=response.data.data;
        this.count_user=response.data.data.count_subscription;
        this.text_copied=response.data.data.code;
      }catch(error)  {
        console.log(error.request);
      }

    }

  },
  mounted() {
    this.userProfit();
  },
};
</script>
<style lang="scss">
.v-row.item_chat_not_read.align-center {
  background-color: rgb(0 0 0 / 9%);
}
section.chats-list {
  margin: 25px 15px;
  border-left: 1px solid #0000002e;
}
.icon-div {
  width: 100px;
  margin: 15px auto;
  //  margin: auto;
  //  width: max-content;
  //  margin-top: 20px;
  //  font-size: 30px;
  //  padding: 14px 15px;
  //  color: #dfb100;
  //  border-radius: 50%;
  //  border: 2px solid #05105a;

}
.item-amount {
  text-align: center;
}
.item-code-text {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border: 1.8px solid #b9b9b9;
  border-radius: 15px;
  color: #0a274a;
}
span.code-share ,span.code-copy {
  float: left;
  padding: 0 10px;
  border-right: 1px solid #b9b9b9;
}

 span.amount-span {
  display: block;
  text-align: center;
  color: #ad8901f5;
  font-size: 19px;
  font-weight: 600;
   direction: rtl;
}
.div-btn-draw {
  margin: 10px auto;
  text-align: center;
}

button.btn-send-draw {
  background-color: #057446f7;
  box-shadow: none;
  color: #fff;
}
.item-code {
  padding-right: 45px;
  margin: auto;
  text-align: center;
  font-weight: 500;
  direction: rtl;
}
.item-code-term {
   margin: 10px;
 }
.pending-amount-div {
  margin-top: 10px;
  text-align: center;
}
span.pending-amount-span {
  padding: 5px;
  border: 1.5px solid;
  color: #a98600;
  border-radius: 5px;
}
i.mdi-exclamation.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default {
  padding: 8px;
  border: 2px solid;
  border-radius: 50%;
  color: #a98600;
  font-size: 20px;
  /* font-weight: 600 !important; */
}
.content-div {
  display: inline-flex;
  align-items: center;
}
span.data-message-item {
  float: left;
  font-size: 11px;
  color: #00000082;
  margin-top: -10px;
}

.trans-head {
  margin: 10px;

}

span.trans-head-title {
  font-weight: 600;
  color: #373740;
}
.item-trans {
  margin: 5px 20px;
  background-color: rgb(0 0 0 / 9%);
  display: inline-flex;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  width: 85%;
}

.item-icon-trans {
  margin: auto;
  padding: 10px;
}
.item-icon-trans {
  margin: auto 10px;
  padding: 5px;
  font-size: 20px;
  color: #317c2b;
  border-radius: 50%;
  border: 2px solid;
}
.item-trans-des {
  width: 80%;
}
.item-trans-des-title {
  font-weight: 500;
  color: #0c1322;
}
.item-trans-des-date {
  float: left;
  color: #00000085;
  font-size: 14px;
}

.item-icon-trans.withdraw {
  color: #b70606 !important;
}

.item-trans.pending_withdraw {
  background-color: #c7000017 !important;
}

.item-trans.pending_deposit {
  background-color: rgb(49 124 43 / 14%) !important;
}

.specialStyle {
  float: left;
  background: #f6c7128c !important;
}

.v-row.align-center.profits-item {
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  width: 80%;
  border: 1px solid rgb(191, 151, 0);
  margin: 15px auto 0px;
  padding: 10px 20px;
}

.v-row.align-center.profits-item.page_active , .v-row.align-center.profits-item:hover {
  background-color: #312f42;
  color: #ddaf00;
}
.sendmsg.v-input--density-default .v-field--variant-outlined,
.sendmsg.v-input--density-default .v-field--single-line,
.sendmsg.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.v-row.active_item_chat.align-center {
  border: 1px solid rgb(191 191 191) !important;
  border-radius: 15px;
  width: 100%;
}
img.rounded-circle {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  box-shadow: 0px 0px 0px 2px #0000002b;
}
.check .v-input__details,
.sendmsg .v-input__details {
  display: none !important;
}
.sendmsg.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
