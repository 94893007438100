<template>
  <section v-if="items">
    <h3 class="text-h4 font-weight-bold text-center mb-8">
      {{ $t("Contact") }}
    </h3>
    <v-row>
      <v-col cols="12" sm="4" class="overflow-hidden">
       
        <v-img :src="contactus" alt="" cover class="rounded style_mon">
          <div class="mt-16 rounded mr-md-10
          mr-8 d-flex flex-column justify-space-between" style="height:85%" >
            <div>
              <p class="text-white text-md-h5 text-h6 mb-4">
              {{ $t("contact_information") }}
            </p>
            <p class="text-white mt-5 mb-5 ml-8 mr-3">
              {{ $t('We_are_here') }}
            </p>
            </div>
          <div>
            <a
              :href="`tel:+${items.phone}`"
              class="text-white text-decoration-none d-block mb-3"
            >
              <v-icon icon="mdi-phone-outline"></v-icon>
              {{ items.phone }}
            </a>
            <a
              :href="`mailto:${items.email}`"
              class="text-white text-decoration-none d-block mb-3"
            >
              <v-icon icon="mdi-email-outline"></v-icon>
              {{ items.email }}
            </a>
            <a
              :href="`https://wa.me/966${whatsapp}`"
              target="_blank"
              class="text-white text-decoration-none d-block mb-3"
            >
              <v-icon icon="mdi-whatsapp" class=""></v-icon>
              {{ items.whatsapp }}
            </a>
            <div class="d-flex justify-space-between w-75">
              <a
                :href="`${items.facebook}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon icon="mdi-facebook" class="mx-1"></v-icon>
              </a>
              <a
                :href="`${items.instagram}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon icon="mdi-instagram" class="mx-1"></v-icon>
              </a>
              <a
                :href="`${items.linkedin}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon icon="mdi-linkedin" class="mx-1"></v-icon>
              </a>
              <a
                :href="`${items.snapchat}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon icon="mdi-snapchat" class="mx-1"></v-icon>
              </a>
          </div>
          
            </div>
          </div>
        </v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row class="mt-sm-1 mt-2">
          <v-col cols="12" lg="6">
            <label class="font-weight-bold">
              {{ $t("firstname") }}
            </label>
            <v-text-field
              variant="solo"
              class="selectnew new tel"
              v-model="formData.fname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <label class="font-weight-bold">
              {{ $t("lastname") }}
            </label>
            <v-text-field
              variant="solo"
              class="selectnew new tel"
              v-model="formData.lname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <label class="font-weight-bold">
              {{ $t("phone_number") }}
            </label>
            <v-text-field
              type="tel"
              variant="solo"
              class="selectnew new tel"
              v-model="formData.phone"
              @input="validatePhoneNumber"
            ></v-text-field>
            <div v-if="invalidPhoneNumber" class="text-red mb-2">
              {{ validationMessage }}
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <label class="font-weight-bold">
              {{ $t("email") }}
            </label>
            <v-text-field
              variant="solo"
              class="selectnew new tel"
              v-model="formData.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" >
            <label class="font-weight-bold">
              {{ $t("Message_Subject") }}
            </label>
            <v-text-field
              variant="solo"
              class="selectnew new tel"
              v-model="formData.subject"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <label class="font-weight-bold">
                {{ $t("message") }}
              </label>
              <span>
                {{ $t("Maximum_characters") }}
              </span>
            </div>

            <v-textarea
              variant="solo"
              class="selectnew new"
              v-model="formData.message"
            ></v-textarea>
          </v-col>
          <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>
        </v-row>

        <button class="pa-2 previewads w-100 mt-4" @click="contact">
          {{ $t("Send") }}
        </button>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackbar" color="yellow-darken-1
">
   {{ $t('You_answered_soon') }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </section>
  <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import contact from "../../assets/images/contactus.png";
import Path from "../../assets/images/Path.png";
export default {
  data() {
    return {
      items: "",
      contactus: contact,
      Path: Path,
      texts: [1, 2, 3, 4],
      showSnackbar :false,
      errorMessage: "",
      invalidPhoneNumber: false,
      validationMessage: "",
      formData:{
        phone: "",
      message: "",
      fname:'',
      whatsapp:'',
      lname:'',
      email:'',
      subject:''
      }
    };
  },
  methods: {
    validatePhoneNumber() {
      const isNegative = this.formData.phone.startsWith("-");
      const isNumeric = /^\d+$/.test(this.formData.phone);
      this.invalidPhoneNumber = isNegative || !isNumeric;
      this.validationMessage = this.$t("enterValidPhoneNumber");
    },
    async getcontact() {
      let response = await crudDataService.getAll("application-data");
      this.items = response.data.data;
      this.whatsapp=this.items.whatsapp.replace(/^0+/, '');

    },
    async contact() {
      let response = await crudDataService
        .create("contact-us", this.formData)
        .then((res) => {
          // console.log(res, "wdjqpowjpwd");
          this.showSnackbar=true
            this.formData.phone= "",
            this.formData.message= "",
            this.formData.lname= "",
            this.formData.fname= "",
            this.formData.email= "",
            this.formData.subject= "",
            this.errorMessage=''
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
    },
  },
  mounted() {
    this.getcontact();
  },
};
</script>
<style lang="scss">
.new.v-textarea .v-field__input,
.new.v-text-field input.v-field__input {
    background: #F7F7F7;
    border-radius: 10px;
}
.tel input {
  text-align: end !important;
}
</style>
