<template>

  <Carousel v-bind="settings" :autoplay="false" :breakpoints="breakpoints" :wrap-around="false" dir="rtl">
    <Slide  v-if="is_nafath_verified !== 1"  :key="0">
      <v-list-item
          color="deep-purple-accent-4"
          :key="0"
          class="itemname pa-0  m-0"
          :class="'/nafath' === selected && 'active'"
          @click="navigateTo('/nafath')"
      >
        <p >
          {{ $t('nafath') }}
        </p>
      </v-list-item>
    </Slide>
    <Slide  v-if="is_marketer"  :key="-1">
      <v-list-item
          color="deep-purple-accent-4"
          :key="0"
          class="itemname pa-0  m-0"
          :class="'/my-profits' === selected && 'active'"
          @click="navigateTo('/my-profits')"
      >
        <p >
          {{ $t("My profits") }}
        </p>
      </v-list-item>
    </Slide>
    <Slide  v-for="(item, index) in items"  :key="index">
      <v-list-item
          color="deep-purple-accent-4"
          :key="index"
          class="itemname pa-0  m-0"
          :class="item.route === selected && 'active'"
          @click="navigateTo(item.route)"
      >
        <p >
          {{ item.text }}
        </p>
      </v-list-item>
    </Slide>

  </Carousel>
</template>
<script>
// import crudDataService from "@/Services/crudDataService";
// import { ref } from 'vue';
// import { useAuth } from "../axios";
import 'vue3-carousel/dist/carousel.css'
import {Carousel,Navigation, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
import crudDataService from "@/Services/crudDataService";
export default {
  components: {Slide,Carousel,Navigation},
  props: {
    selected: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      is_nafath_verified:1,
      is_marketer:false,
      items: [
        { text: this.$t("Account_settings"), route: "/account-settings" },
        { text: this.$t("Change_Password"), route: "/account-settings/change-password" },
        { text: this.$t("My_ads"), route: "/my-ads" },
        { text: this.$t("Favorite_ads"), route: "/favorite-ads" },
        { text:  this.$t("chat") , route: "/chats" },
        { text: this.$t("Followers"), route: "/followers" },
        { text: this.$t("Subscriptions"), route: "/subscriptions" },
        { text: this.$t("Commission"), route: "/commission" },
        { text: this.$t("Blocked_Persons"), route: "/blocked-persons" },

      ],
      breakpoints: {
        // 700px and up
        300: {
          itemsToShow: 4,
          snapAlign: 'end',
        },
        // 700px and up
        400: {
          itemsToShow: 4,
          snapAlign: 'end',
        },
        // 700px and up
        500: {
          itemsToShow: 5,
          snapAlign: 'end',
        },
        // 700px and up
        600: {
          itemsToShow: 6,
          snapAlign: 'end',
        },
        // 700px and up
        700: {
          itemsToShow: 6,
          snapAlign: 'end',
        },
        // 1024 and up
        1024: {
          itemsToShow: 8,
          snapAlign: 'end',
        },
      },
      settings: {
        itemsToShow: 4,
        snapAlign: 'end',
      },
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    async userprofile() {
      const id = localStorage.getItem("userId");
      let response = await crudDataService.get(`user-profile`, id);
      this.is_nafath_verified=response.data.data.is_nafath_verified;
      this.is_marketer=response.data.data.is_marketer;

    }
  },
  mounted() {
    this.userprofile();
  },
};
</script>
<style lang="scss" scoped>
.v-list-item.v-list-item--link.v-theme--light.v-list-item--density-default.v-list-item--variant-text.itemname.pa-0.m-0 {
  width: 96%;
  padding: 10px 0 !important;
  border-radius: 5px;
  background-color: rgba(231, 215, 142, 0.3803921569);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
li.carousel__slide.carousel__slide--visible.active {
  border-bottom: 2px solid #c5a300f7;
}

</style>
