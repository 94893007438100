<template>
  <ProfileTap :selected="'/nafath'"></ProfileTap>

  <v-container v-if="is_nafath_verified===0">
    <h2 class="text-center mb-2">
      {{ $t("verification_nafath") }}
    </h2>


    <div v-if="!showNumber">
      <v-row>
        <v-col cols="12" sm="3">
          <label class="font-weight-bold">
            {{ $t("nationalId") }}
          </label>
          <v-text-field
              type="text"
              variant="solo"
              class="selectnew"
              v-model="formData.nationalId"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <label class="font-weight-bold">
            {{ $t("identity_type") }}
          </label>
          <multiselect
              :options="options"
              :searchable="true"
              :selected="null"
              label="name"
              v-model="formData.identity_type"
          ></multiselect>

        </v-col>
        <v-col v-if="formData.identity_type === 2||formData.identity_type === 3" cols="12" sm="3">
          <label class="font-weight-bold">
            {{ $t("name_") }}
          </label>
          <v-text-field
              type="text"
              variant="solo"
              class="selectnew"
              v-model="formData.name"
          ></v-text-field>
        </v-col>
        <v-col v-if="formData.identity_type === 2 ||formData.identity_type === 3" cols="12" sm="3">
          <label class="font-weight-bold">
            {{ $t("unified_number") }}
          </label>
          <v-text-field
              type="text"
              variant="solo"
              class="selectnew"
              v-model="formData.unified_number"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>
      <div class="u-mt-medium u-color-secondary" style="
    justify-content: center;
    margin: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
">
        <p tabindex="0"> {{$t('To download nafath app')}}</p>
      </div>
      <div class="d-flex justify-content-center payment-method" style="justify-content: center">
        <a id="link" href="https://apps.apple.com/sa/app/نفاذ-nafath/id1598909871" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="app" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
        <a id="link_andr" href="https://play.google.com/store/apps/details?id=sa.gov.nic.myid" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="google" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
        <a id="link_andr" href="https://appgallery.huawei.com/app/C106870695" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="exp" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
      </div>
      <div class="text-center">
        <button class="previewads w-50 pa-2 mt-5" @click="add">
          {{ $t("send_request") }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center payment-method" style="justify-content: center;">
          <img :src="nafath_logo" alt="Nafath App" width="80" height="80" loading="lazy"  style=" border-radius: 5px;">
      </div>
      <div class="d-flex justify-content-center payment-method" style="justify-content: center;margin: 20px;">
          <span style="
    padding: 10px 15px;
    background-color: #cbcbcb;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
">
            {{number}}
          </span>
      </div>
      <div class="u-mt-medium u-color-secondary" style="
    justify-content: center;
    margin: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
">
        <p tabindex="0"> {{$t('To download nafath app')}}</p>
      </div>
      <div class="d-flex justify-content-center payment-method" style="justify-content: center">
        <a id="link" href="https://apps.apple.com/sa/app/نفاذ-nafath/id1598909871" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="app" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
        <a id="link_andr" href="https://play.google.com/store/apps/details?id=sa.gov.nic.myid" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="google" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
        <a id="link_andr" href="https://appgallery.huawei.com/app/C106870695" target="_blank" tabindex="0" style="
    margin: 5px 10px;border-radius: 5px;
">
          <img :src="exp" alt="Nafath App" width="120" height="40" loading="lazy">
        </a>
      </div>
    </div>

  </v-container>
  <v-container v-else>

  </v-container>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
import Multiselect from "@vueform/multiselect";
import ProfileTap from "@/views/ProfileTap.vue";
import google from "@/assets/images/pngwing.jpeg";
import app from "@/assets/images/pngw.png";
import exp from "@/assets/images/pngwi.jpg";
import nafath_logo from "@/assets/images/nafath.png";
export default {
  components: {
    ProfileTap,
    Multiselect,

  },
  data() {
    return {
      is_nafath_verified:0,
      nafath_logo:nafath_logo,
      google:google,
      app:app,
      exp:exp,
      status:0,
      errorMessage: "",
      options: [{ value: 1, name: this.$t('individual') },
        { value: 2, name: this.$t('institution') },
        { value: 3, name: this.$t('institution_3') }
      ],
      number:'',
      transId:'',
      showNumber:false,
      formData: {
          name: "",
          unified_number: "",
          nationalId: "",
          identity_type: 1,
      },
    };
  },
  methods: {
    startCheckingNafath() {
      if (this.checkInterval) clearInterval(this.checkInterval);
      this.checkInterval = setInterval(() => {
        this.checkNafath();
      }, 1000);
    },
    async add() {
      let response = await crudDataService
          .create(`nafath-request`, this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.data) {
              this.showNumber=true;
              this.number=res.data.data.random;
              this.transId=res.data.data.transId;
            }else {
              this.errorMessage = res.data.error;

            }


          })
          .catch((error) => {
            if (error.response) {
              // The API responded with an error message
              this.errorMessage = error.response.data.message;
            } else if (error.request) {
              this.errorMessage = "No response from the server";
            } else {
              this.errorMessage = error.message;
            }
          });
    },
    async checkNafath() {
      let response = await crudDataService
          .getAll(`nafath-status`)

          .then((res) => {
            if(res.data.status === 1){
              this.$router.push('/')
              this.$swal.fire({
                title: this.$t("success_status_nafath"),
                position: "top-start",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500,
                icon: "success",
                customClass: {
                  icon: "my-custom-class",
                  title: "swal2-title",
                },
              });
              window.location.reload();

            }

          })
          .catch((error) => {
            if (error.response) {
              // The API responded with an error message
              this.errorMessage = error.response.data.message;
            } else if (error.request) {
              this.errorMessage = "No response from the server";
            } else {
              this.errorMessage = error.message;
            }
          });

    },
    async userprofile() {
      const id = localStorage.getItem("userId");
      let response = await crudDataService.get(`user-profile`, id);
      this.formData.name=response.data.data.name;
      this.is_nafath_verified=response.data.data.is_nafath_verified;
      if (this.is_nafath_verified) {
        this.$router.push('/');
      }
    },

  },
  watch: {
    showNumber(newValue) {
      if (newValue === true) {
        this.startCheckingNafath();
      }
    }
  },
  mounted() {

    this.userprofile();
  },
};
</script>

<style lang="scss">
.selectnew {
  &.multiselect {
    margin-top: 8px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .multiselect-search {
    border-radius: 10px;
  }
}
.selectnew.v-input--density-default .v-field--variant-outlined,
.selectnew.v-input--density-default .v-field--single-line,
.selectnew.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  border-radius: 10px !important;
  margin-top: 6px;
  margin-bottom: 8px;
}
.check .v-input__details,
.selectnew .v-input__details {
  display: none !important;
}
.selectnew.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
