<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="7" md="5" class="pa-xs-3 pa-0 py-5">
      <v-card class="pa-xs-5 px-2 card_login" elevation="0">
        <v-card-title class="text-h4 weight_text">
          {{ $t("otp_account") }}
        </v-card-title>
        <p class="weight_text">
          {{ $t("welcome_otp") }}
        </p>
        <v-card-text>
          <v-form>

            <label class="weight_text">
              {{ $t("code_otp") }}
            </label>
            <v-otp-input v-model="formData.code" length="4" class="mt-2 " style="direction: rtl" @finish="handleOtpComplete" />



            <v-alert
                v-if="errorMessage"
                type="error"
                dismissible
                class="mt-3"
            >{{ errorMessage ? errorMessage : "" }}</v-alert>
            <v-btn
                :disabled="formData.code.length < 4"
                class="previewads d-block w-100 mt-6"
                @click="verifyOtp"
            >
              {{ $t("Verify") }}
            </v-btn>
          </v-form>
          <div class="justify-center mt-6 d-flex text-h6">
            <p class="mx-1">
              {{ $t("DontHaveCode") }}
            </p>
            <button class="font-weight-bold mx-1" @click="SendAgain()">
              <h3>
                {{ $t("SendAgain") }}
              </h3>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
import { useAuth } from "../../axios";
import axios from "axios";

export default {
  data() {
    return {
      formData: {
        code: "",
        phone: "",
      },
      errorMessage: "",
      visible: false,
      visible1: false,
      invalidPhoneNumber:false,
      invalidat:false,
      validationMessage:"",
      validat:""
    };
  },
  methods: {
    handleOtpComplete(value) {
      console.log("OTP مكتمل:", value);
      this.formData.code = value;
    },
    async verifyOtp() {

      // this.$router.push({ name: 'Nafath' })
      try {

      let  phone = '0'+localStorage.getItem('phone_otp');

      if (!phone) {
        this.$router.push({ name: 'LogIn' });
        window.location.reload();
      }
        if (this.formData.code.length < 4) {
          this.errorMessage = this.$t('error_otp_account');
        }else {
          const response = await crudDataService
              .create(`verify`, {
                phone: phone,
                code: this.formData.code,
              })
          this.$router.push({ name:response.data.data.is_nafath_verified ? 'home':'Nafath'})

              .then(() => {
                if (response.data.data.is_verified) {
                  this.$swal.fire({
                    title: response.data.message,
                    position: "top-start",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1500,
                    icon: "success",
                    customClass: {
                      icon: "my-custom-class",
                      title: "swal2-title",
                    },
                  });
                  localStorage.setItem('userId', response.data.data.id);
                  localStorage.setItem('authToken', response.data.data.token);
                  window.location.reload();
                }
              });
        }

      }catch(error)  {
        if (error.response) {
          // The API responded with an error message
          this.errorMessage = error.response.data.message;
        } else if (error.request) {
          this.errorMessage = 'No response from the server';
        } else {
          this.errorMessage = error.message;
        }
        console.error(' error:', this.errorMessage);
      }
    },
    SendAgain(isFirst=false) {
      let authToken_otp =  localStorage.getItem('authToken_otp');
      if (!authToken_otp) {
        this.$router.push({ name: 'LogIn' });
      }
      axios.get("https://dev.aqarcom.sa/api/resend", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken_otp}`,
        }
      })
          .then(response => {
            if(!isFirst){
              console.log(response)
              this.$swal.fire({
                title: response.data.message,
                position: "top-start",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500,
                icon: "success",
                customClass: {
                  icon: "my-custom-class",
                  title: "swal2-title",
                },
              });

            }
          })
          .catch(error => {
            if(!isFirst) {
              this.$swal.fire({
                title: error.response.data.message,
                position: "top-start",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500,
                icon: "error",
                customClass: {
                  icon: "my-custom-class",
                  title: "swal2-title",
                },
              });
            }
          });
      console.log("SendAgain");
    },
  },
mounted() {
  this.SendAgain(true);
},
};
</script>

<style scoped lang="scss">
.v-alert__content {
  color: #780000;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.card_login {
  .weight_text {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
  }
  .previewads {
    height: 45px;
    font-weight: 900;
  }
}
</style>
<style lang="scss">
.tel input {
    text-align: end !important;
}
</style>