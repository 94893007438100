<template>
  <ProfileTap :selected="'/blocked-persons'"></ProfileTap>

  <v-container>
        <section v-if="items.length>0">
          <div class="d-flex flex-wrap justify-start pt-7">
              <v-row class="text-center">
                  <v-col cols="12" md="4" sm="6"
              v-for="(item, i) in items"
              :key="i">
              <img :src="item.image" class=" rounded-circle" style="height: 190px"/>
              <p style="font-size: 20px" class="font-weight-medium text-center my-2">
                {{ item.name }}
              </p>
                  </v-col>
              </v-row>

          </div>
        </section>
        <section v-else-if="items.length==0">

    <div
        class="font-weight-medium text-center py-16 mb-16 "
        style="font-size: 30px; cursor: pointer"
        @click=""
      >
      {{ $t("no_blocked") }}
      </div>
 
        </section>
     
    <v-row v-else class="skeleton">
    <v-col cols="12" md="12">
      <v-skeleton-loader type="paragraph" width="50%" class="ma-auto"></v-skeleton-loader>
    <v-skeleton-loader type="paragraph" width="50%" class="ma-auto"></v-skeleton-loader>
    </v-col>
    <v-col cols="12" md="2" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0 rounded-circle"
        type="image"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
    </v-container>
      
  </template>
  <script>
  import crudDataService from "@/Services/crudDataService";
  import ProfileTap from "@/views/ProfileTap.vue";
  
  export default {
    components: {ProfileTap},
   
    data() {
      return {
        items: [],
      };
    },
    methods: {
      async userblocks() {
        let response = await crudDataService.getAll(`user-blocks`);
       this.items=response.data.data
      },
    },
    mounted() {
      this.userblocks();
    },
  };
  </script>
  <style></style>
  