<template>
  <div class="text-center allbutton">
    <button class="px-2 searchmap ma-1 "       :class="{ active: (activeRoute === 'Map' || activeRoute === 'AllINCity') }"
            @click="gotomap">{{ $t("SearchMap") }}</button>
  <button class="px-2 searchmap ma-1 "      :class="{ active: activeRoute === 'Addrequest' }" @click="Addrequest">{{ $t("Real_request") }}</button>
  <button class="px-2 searchmap ma-1 "      :class="{ active: activeRoute === 'Addoffer' }" @click="Addoffer">{{ $t("Real_offer") }}</button>

  </div>

</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeRoute: this.$route.name,
    };
  },
  watch: {
    "$route.name"(newRoute) {
      this.activeRoute = newRoute;
    },
  },
  methods: {
    gotomap(){
        this.$router.push({name:'Map'})
    },
    Addrequest(){
        this.$router.push({name:'Addrequest'})
    },
    Addoffer(){
        this.$router.push({name:'Addoffer'})
    }
  },
};
</script>
<style lang="scss" scoped>

.allbutton .searchmap{
  border: 1px solid #e3b400 !important;
  background-color: #a587153b !important;
   border-radius: 6px;
   font-weight: 500;
   height: 40px;
   width: 120px;
}
.allbutton .searchmap:hover{
  background-color: transparent;
  color: #0c0c0c;

}
.searchmap.active{
  background-color:#c3a53494 !important;
}
@media (max-width: 991.98px) {
  .allbutton .searchmap {
    height: 35px !important;
    font-size: 15px !important;
    width: 110px !important;
  }


}
</style>
