<template>
  <section id="target-section">
    <v-container>
      <v-row v-if="itemlist">
        <v-col cols="12" md="2">
          <Multiselect
            :options="optionsarea"
            :searchable="true"
            :placeholder="$t('Area')"
            label="name"
            class="selectinput"
            v-model="area_id"
            @change="(selected) =>handlePageChange('', '', '', '', selected, param,true)"
          />
        </v-col>
        <v-col cols="12" md="2">
          <multiselect
            :options="optionscity"
            :searchable="true"
            :placeholder="$t('City')"
            label="name"
            class="selectinput"
            v-model="city_id"
            @change="(selected) =>handlePageChange('', '', selected, '', '', param)"
          ></multiselect>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="position-relative custominput"
            density="compact"
            :placeholder="$t('Placesearch')"
            single-line
            hide-details
            elevation="0"
            v-model="search"
            @change="handlePageChange('', '', '', search, '', param)"
            type="text"
          >
            <div
              class="position-absolute d-flex justify-space-between align-center"
              style="width: 96% !important"
            >
              <v-icon icon="mdi-magnify"> </v-icon>
              <v-icon icon="mdi-filter-outline"></v-icon>
            </div>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn class="previewads" elevation="0">
            {{ $t("Searchnow") }}
            <v-icon class="arrow" icon="mdi-chevron-left"> </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="2">
          <Multiselect
            :options="optionsarea"
            :searchable="true"
            :placeholder="$t('Area')"
            label="name"
            class="selectinput"
            v-model="area_id"
            @change="supportive(city_id,area_id)"
          />
        </v-col>
        <v-col cols="12" md="2">
          <multiselect
            :options="optionscity"
            :searchable="true"
            :placeholder="$t('City')"
            label="name"
            class="selectinput"
            v-model="city_id"
            @change="supportive(city_id,area_id)"
          ></multiselect>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="position-relative custominput"
            density="compact"
            :placeholder="$t('Placesearch')"
            single-line
            hide-details
            elevation="0"
            v-model="search"
            @change="supportive(city_id,area_id)"
            type="text"
          >
            <div
              class="position-absolute d-flex justify-space-between align-center"
              style="width: 96% !important"
            >
              <v-icon icon="mdi-magnify"> </v-icon>
              <v-icon icon="mdi-filter-outline"></v-icon>
            </div>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn class="previewads" elevation="0">
            {{ $t("Searchnow") }}
            <v-icon class="arrow" icon="mdi-chevron-left"> </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <Carousel v-bind="settings" :autoplay="false" :breakpoints="breakpoints" :wrap-around="false" dir="rtl">
      <Slide :key="0" class="">
        <v-list-item
            color="deep-purple-accent-4"
            :class="null === selected && 'active'"
            class="itemname pa-0 m-0"
            @click="
          handlePageChange(
            estate_type = '',
            ad_type='' ,
            city_id,
            search,
            area_id,
            param
          )
        "
        >
          <p style="padding: 7px 11px; " @click="onClickDescription(null)">
            {{ $t('all') }}
          </p>
        </v-list-item>
      </Slide>
      <Slide v-for="(list1, ii) in list" :key="ii">
        <v-list-item
            color="deep-purple-accent-4"
            :key="ii"
            class="itemname pa-0  m-0"
            :class="ii === selected && 'active'"
            @click="
          handlePageChange(
            (estate_type = list1.estate_type_id),
            (ad_type = list1.ad_type_id),
            city_id,
            search,
            area_id,
            param
          )
        "
        >
          <p style="padding: 7px 11px;" @click="onClickDescription(ii)">
            {{ list1.name }}
          </p>
        </v-list-item>
      </Slide>

    </Carousel>

    <v-container>
      <v-row class="justify-center">
        <v-col cols="12" md="7">
          <div class="collection-real">

            <button style="width: 48% !important;"
              class="button-real mx-1"
              :class="{ active: btnactive }"
              @click="
                handlePageChange(
                  estate_type,
                  ad_type,
                  city_id,
                  search,
                  area_id,
                  'sell'
                )
              "
            >
              <p>
                {{ $t('Real_offers') }}
              </p>
            </button>
            <button  style="width: 48% !important;"
              class="button-real mx-1"
              :class="{ active: btnsellactive }"
              @click="
                handlePageChange(
                  estate_type,
                  ad_type,
                  city_id,
                  search,
                  area_id,
                  'buy'
                )
              "
            >
              <p>
                {{ $t('Real_requests') }}
              </p>
            </button>
<!--            <button-->
<!--              class="button-real mx-1"-->
<!--              :class="{ active: buttonactive }"-->
<!--              @click="supportive(city_id, area_id)"-->
<!--            >-->
<!--              <p>-->
<!--                {{ $t("supportive_services") }}-->
<!--              </p>-->
<!--            </button>-->
          </div>
        </v-col>
      </v-row>
      <v-row >
      
        <v-col cols="12" md="3" v-for="(ad, i) in items" :key="i"  >
          <Card
            :image="ad.attachment[0]?ad.attachment[0]:''"
            :title="ad.price"
            :subtitle="ad.estate_type?ad.estate_type:ad.title + ' ' + ad.ad_type?ad.ad_type:''"
            :city="ad.city"
            :date="ad.publish_date?ad.publish_date:''"
            :type="ad.estate_type?ad.estate_type:ad.support_service"
            :idcard="ad.id"
            :userid="ad.id?ad.user.id:''"
           ></Card>
   
          <!-- :numwidth="ad.area.split('.')[0]" -->
        </v-col>
    
     
        <v-col cols="12" md="3" v-for="(ad, i) in itemssupportive" :key="i" >
          <Card
            :image="ad.attachments[0].path?ad.attachments[0].path:''"
            :title="ad.price"
            :subtitle="ad.estate_type?ad.estate_type:ad.title + ' ' + ad.ad_type?ad.ad_type:''"
            :city="ad.city"
            :date="ad.publish_date?ad.publish_date:''"
            :type="ad.estate_type?ad.estate_type:ad.support_service"
            :idcard="ad.id"
            :userid="ad.id?ad.user.id:''"
            v-if="itemssupportive.length>0"></Card>
            <div v-else>
              <v-card
   class="mx-auto w-100
   my-12 bg-yellow-darken-2 pa-5"
 >

   <v-card-text style="font-size: 33px;">
       {{ $t('There_no_services') }}
   </v-card-text>
 </v-card>
            </div>
          <!-- :numwidth="ad.area.split('.')[0]" -->
        </v-col>
        </v-row>
        <v-row >
        <v-col cols="12" v-if="aboutcheck" >
         
         <v-card
   class="mx-auto
   my-12 bg-yellow-darken-2 pa-5"
 >

   <v-card-text style="font-size: 33px;">
       {{ $t('There_no_services') }}
   </v-card-text>
 </v-card>
       </v-col>
      </v-row>
      <v-pagination
        class="paginate"
        v-model="page"
        :length="pagination.last_page"
        :total-visible="5"
        @update:model-value="handlePageChange()"
        v-if="items.length > 0"
      ></v-pagination>
      <v-pagination
        class="paginate"
        v-model="page"
        :length="paginationsupportive.last_page"
        :total-visible="5"
        @update:model-value="supportive()"
        v-else
      ></v-pagination>
    </v-container>
  </section>
</template>

<script>
import Multiselect from "@vueform/multiselect";

import Card from "../Card/Card.vue";
import crudDataService from "@/Services/crudDataService";
import {Carousel,Navigation, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Slide,
    Navigation,
    Multiselect,
    Carousel,
    Card,
  },
  data() {
    return {
      settings: {
        itemsToShow: 4,
        snapAlign: 'end',
      },
      breakpoints: {
        // 700px and up
        300: {
          itemsToShow: 4,
          snapAlign: 'end',
        },
        // 700px and up
        400: {
          itemsToShow: 4,
          snapAlign: 'end',
        },
        // 700px and up
        500: {
          itemsToShow: 5,
          snapAlign: 'end',
        },
        // 700px and up
        600: {
          itemsToShow: 6,
          snapAlign: 'end',
        },
        // 700px and up
        700: {
          itemsToShow: 6,
          snapAlign: 'end',
        },
        // 1024 and up
        1024: {
          itemsToShow: 13,
          snapAlign: 'end',
        },
      },
      itemlist:true,
      optionscity: [],
      optionsarea: [],
      items: [],
      itemssupportive: [],
      paginationsupportive: [],
      pagination: [],
      options: [],
      page: 1,
      list: [],
      ad_type: "",
      estate_type: "",
      param: "sell",
      selected: null,
      itembutton: [
        { title: "Real_offers", id: 1, text: "sell", active: true },
        { title: "Real_requests", id: 2, text: "buy", active: false },
      ],
      city_id: "",
      area_id: "",
      search: "",
      imageforsuporive:'',
      btnactive:true,
      btnsellactive:false,
      buttonactive:false,
      aboutcheck:false
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newer) {
        // // console.log("Watcher triggered:", newer.estate_type, newer.ad_type);
        // rest of your code...;
        // this.active(newer.param?newer.param:this.param)
        if(newer.support){
          this.supportive()
        }else{
          this.handlePageChange(
          newer.estate_type,
          newer.ad_type,
          this.city_id,
          this.search,
          this.area_id,
          newer.param ? newer.param : this.param
        );
        }
       
      },
    },
  },
  methods: {
    async supportive(
      city_id,
      area_id,
    ) {
      (this.city_id = city_id || `${this.city_id}`),
        (this.area_id = area_id || `${this.area_id}`);
    this.buttonactive = true; // Set this button as active
    this.btnactive=false,
      this.btnsellactive=false,
      this.itemlist=false
      this.items=[]
      let response = await crudDataService.getsuport("support-service-ads",
      {
        perPage: 12,
          city_id: this.city_id || ``,
          page: `${this.page}` || ``,
          area_id: this.area_id || ``,
          search: `${this.search}` || ``,

        });
      this.itemssupportive = response.data.data.ads;
      // // console.log(this.itemssupportive);

      // // // console.log(this.items);
      // // // console.log(this.items);
      this.paginationsupportive = response.data.data.meta;
      this.itemssupportive.forEach((element) => {
        this.options = element.options;
      });
      this.itemssupportive.forEach((element) => {
       this.imageforsuporive = element.attachments[0].path;
      });
      // // console.log(this.itemssupportive.length);
      if (this.itemssupportive.length==0) {
        this.aboutcheck=true
      }else{
        this.aboutcheck=false
      }
    },
    async Areas() {
      let response = await crudDataService.getAll(`areas`);
      this.optionsarea = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async City(change_area=false,area_id=null) {

      if (change_area && area_id !== 'null') {
        let response = await crudDataService.getAll(`cities?area_id=${area_id}`);
        this.optionscity = response.data.data.map((state) => ({
          value: state.id,
          name: state.name,
        }));
      }else{
        let response = await crudDataService.getAll(`cities`);
        this.optionscity = response.data.data.map((state) => ({
          value: state.id,
          name: state.name,
        }));
      }


    },
    single(id){
      this.$router.push({ name: 'SinglePartner', params:{ id } })
    },
    // singlesupport(id){
    //   this.$router.push({ name: 'Singlesupport', params:{ id } })
    // },
    onClickDescription(i) {
      this.selected = i;
    },
    async handlePageChange(
      estate_type,
      ad_type,
      city_id,
      search,
      area_id,
      param ,
      change_area=false
    ) {
      if(this.city_id==='null'){
        this.city_id='';
      }
      if(this.area_id==='null'){
        this.area_id='';
      }

      if(change_area){
        this.City(change_area,area_id);
      }

      // sell?perPage=6&estate_type_id=&city_id=&ad_type_id=&page=1&area_id=&search=	200	xhr	xhr.js:256	4.0 kB	1.72 s	types	200	xhr	xhr.js:256	731 B	1.40 s	areas	200	xhr	xhr.js:256	491 B	1.49 s	cities	200	xhr	xhr.js:256	5.0 kB	1.32 s	sell?perPage=6&estate_type_id=&city_id=&ad_type_id=&page=1&area_id=&search=	200	xhr	xhr.js:256	4.0 kB	1.84 s	sell?perPage=6&estate_type_id=&city_id=&ad_type_id=&page=1&area_id=&search=	200	xhr	xhr.js:256	4.0 kB	1.70 s
      this.itemlist=true;

      (this.estate_type = estate_type || `${this.estate_type}`),
        (this.ad_type = ad_type || `${this.ad_type}`),
        (this.param = param || `${this.param}`),
        (this.city_id = city_id || `${this.city_id}`),
        (this.area_id = area_id || `${this.area_id}`);
      // (this.search = search);
      let response = await crudDataService.filter(
        `ads`,
        param || `${this.param}`,
        {
          perPage: 12,
          estate_type_id: estate_type || ``,
          ad_type_id: ad_type || ``,
          city_id: this.city_id || ``,
          page: `${this.page}` || ``,
          area_id: this.area_id || ``,
          search: `${this.search}` || ``,
        }
      );
      // // console.log("estate_type", estate_type);
      // // console.log("adtype", ad_type);
      // // console.log("param", param);
      this.items = response.data.data.ads;
      // // console.log(this.items);
      // // console.log(this.items);
      this.pagination = response.data.data.meta;
      this.items.forEach((element) => {
        this.options = element.options;
      });
      if (param==='sell') {
        this.buttonactive = false; // Reset the separate button's active state
        this.btnactive=true,
      this.btnsellactive=false
      }else if (param==='buy') {
        this.buttonactive = false; // Reset the separate button's active state
        this.btnactive=false,
      this.btnsellactive=true
      }
      if (this.items.length==0) {
        this.aboutcheck=true
      }else{
        this.aboutcheck=false
      }
    },

    async alltypes() {
      let response = await crudDataService.getAll(`types`);
      this.list = response.data.data;
    },
  },
  mounted() {
    this.handlePageChange();
    this.alltypes();
    this.Areas();
    this.City();
  },
};
</script>
<style lang="scss">

.collection-real {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  .button-real {
    padding: 5px 15px;
    border-radius: 8px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    width: calc(95% / 3);
    &.active {
      background: #f6c712;
    }
  }
}
nav.v-pagination.v-theme--light.paginate {
  margin: auto;
}
.paginate {
  margin-top: 15px;
  .v-btn--icon .v-icon {
    transform: rotateY(180deg);
  }
}
[dir="ltr"] {
  .paginate .v-btn--icon .v-icon {
    transform: none;
  }
}
.itemname {
  border-radius: 12px;
  margin: 0 10px 5px;
  background: #f9f9f9;
  &.active {
    border: 1px solid #f6c712;
    box-shadow: 0px 3px 8px 0px #6b6b6b2e;
  }
}

.listtype::-webkit-scrollbar {
  height: 0 !important;
}
.custominput {
  &.v-text-field .v-field__input {
    align-items: center;
    input {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  .v-field--variant-filled .v-field__outline::before,
  .v-field--variant-underlined .v-field__outline::before {
    border-style: none;
  }
}
.selectinput {
  &.multiselect {
    border: none !important;
    height: 100%;
  }
  .multiselect-search {
    background: #d9d9d938 !important;
  }
}
</style>
<style lang="scss" scoped>
.m-0 {
  margin: 0 !important;
}


</style>
