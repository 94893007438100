<template >
    <section>
    <div
          style="
          position: fixed;
    right: 10%;
    bottom:10px;
    z-index: 555;
    cursor: pointer;
          "
          v-if="whatsapp"
        >
     
          <a :href="`https://wa.me/966${whatsapp}`" class="text-black iconwhatsapp" target="_blank">
        
            <v-icon icon="mdi-whatsapp" class="mx-1" size="35"></v-icon
          ></a>
  
        </div>
    </section>
    
</template>
<script>
import crudDataService from '../../Services/crudDataService';
export default {
    data(){
        return{
          dataApp:'',
          whatsapp:'',
        }
    },
    methods:{
        async userprofile() {

        let response = await crudDataService.getAll("application-data");
        this.dataApp=response.data.data;
        this.whatsapp=this.dataApp.whatsapp.replace(/^0+/, '');
    },
    },
    mounted() {
        this.userprofile()
    },
}
</script>
<style>
     .iconwhatsapp{
        background: rgb(246, 199, 18);
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: grid;
    text-align: center;
    place-items: center;
    text-decoration: none;
    margin-bottom: 5px;
    }
</style>