<template>
  <section v-if="user">
    <v-container>


      <v-dialog v-model="dialog" width="500">
        <v-card class="text-center py-5">
          <img :src="warning" width="100" class="mx-auto" alt="" />
          <h3>
            {{ $t("Do_block_advertiser") }}
          </h3>
          <div class="d-flex justify-center align-center mt-2">
            <button
                @click="blocked(user.id)"
                class="previewads pa-1 mx-1 rounded"
                style="width: 100px"
            >
              {{ $t("yes") }}
            </button>
            <button
                @click="dialog = false"
                class="border rounded text-black pa-1 mx-1"
                style="width: 100px"
            >
              {{ $t("no") }}
            </button>
          </div>
        </v-card>
      </v-dialog>

      <v-card-title
          class="mx-auto mt-2 rounded pa-2 pt-1 text-h5 font-weight-medium d-flex w-100"
          style="width: max-content"
      >
        <div class="w-50">
          <span class="mx-1">
            {{ addetail.name }}
          </span>
          <br>
          <v-card-title class="pa-0">
            <v-icon icon="mdi-map-marker-outline"></v-icon>
            {{ addetail.city }}
          </v-card-title>
        </div>

        <div class="w-50 text-h5 font-weight-medium text-grey-darken-1 text-left" style="margin: auto">
          {{ addetail.publish_date_format }}
        </div>

      </v-card-title>
      <v-card class="mx-auto  card-head-pac d-flex" elevation="0">
        <div class="v-col-md-7">
<!--          <vue-picture-swipe :items="itemsimage"></vue-picture-swipe>-->
          <swiper
              :modules="modules"
              :loop="true"
              :spaceBetween="10"
              :zoom="true"
              :navigation="true"
              :thumbs="{ swiper: thumbsSwiper }"
              class="main-swiper"
              style="max-height: 400px"
          >
            <swiper-slide v-for="(img, index) in itemsimage"
                          :key="index"
                          @click="openImage(index)"
                          style="height: 400px;
                    background-color: #ffffff;"
                        >


              <video v-if="isVideo(img.src)" :src="img.src" controls style="max-height: 400px;"></video>

              <img v-else :src="img.src" alt="ad image" style="max-height: 400px;min-height: 400px"/>
            </swiper-slide>
          </swiper>
          <swiper
              @swiper="setThumbsSwiper"
              :modules="modules"
              :slidesPerView="4"
              :freeMode="true"
              :loop="true"
              :zoom="true"
              style="max-height: 120px"
              class="thumb-swiper"
          >
            <swiper-slide v-for="(img, index) in itemsimage" :key="index" style="max-height: 120px">

              <video v-if="isVideo(img.src)" :src="img.src" muted style="max-height: 120px;"></video>

              <img v-else :src="img.thumbnail || img.src" alt="ad thumb" style="max-height: 120px;" />

<!--              <img :src="img.thumbnail" alt="ad thumb" style="max-height: 120px" />-->
            </swiper-slide>
          </swiper>
          <easy-lightbox
              ref="lightboxRef"
              :imgs="itemsimage"
              :visible="isPictureSwipeOpen"
              :index="currentImageIndex"
              @hide="closeGallery"
              @close="closeGallery"
          />
        </div>
        <div class="v-col-md-5 left-bar ">
          <div class="item-price">
            <span class="span-price">
               {{ addetail.price.split('.')[0] }}
                {{ $t("SR") }}
            </span>
          </div>


          <div class="mor-item text-start mt-4"  v-if="addetail.platform">
            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op"> {{ $t("Advertising_number") }}: </label>
              <p class="w-50 text-item-left text-item-op">
                {{ addetail.advertiser_registration_number }}
              </p>
            </div>

            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op"> {{ $t("property_type") }}: </label>
              <p class="w-50 text-item-left text-item-op">
                {{ addetail.estate_type }}
              </p>
            </div>

            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op"> {{ $t("Advertising_using") }}: </label>
              <p class="w-50 text-item-left text-item-op">
                {{ addetail.usage_type }}
              </p>
            </div>


            <div class="text-grey mb-2 d-flex" v-if="addetail.platform">
              <label class="w-50 text-item-right text-item-op"> {{ $t("Advertising_date") }}: </label>
              <p class=" w-50 text-item-left text-item-op">
                {{ addetail.platform.creationDate }}
              </p>
            </div>
            <div class="text-grey mb-2 d-flex"  v-if="addetail.platform">
              <label class="w-50 text-item-right text-item-op"> {{ $t("Advertising_exp_date") }}: </label>
              <p class=" w-50 text-item-left text-item-op">
                {{ addetail.platform.endDate }}
              </p>
            </div>
          </div>


          <hr class="pas-more"/>
          <h1 class="text-start mt-2">
            {{ $t("Advertiser_data") }}
          </h1>
          <v-row class="mt-4 align-center" >
            <v-col cols="5" md="2" style="padding: 0!important;"  class="company-image">
              <img :src="user.image" class="w-100" />
            </v-col>
            <v-col cols="6" md="9" class="company-data">
              <h1 class="name-company">
                {{  user.name == "" ? (addetail.platform ? addetail.platform.advertiserName :"") : user.name }}
                <v-icon
                    icon="mdi-check-decagram"
                    v-if="user.is_nafath_verified"
                ></v-icon>
              </h1>
            </v-col>

            <v-col cols="12" md="12">

              <div class="div-icon-user" style=" margin: 0 25%;cursor: pointer;display: flex;text-align: center;align-items: center; ">
                <a :href="`tel:+${user.phone}`" class="text-black iconssocials"
                >
                  <v-icon icon="mdi-phone" class="mx-1"></v-icon
                  ></a>

                <v-icon icon="mdi-forum-outline" class="iconssocials" @click="navigateTo('/chats')"></v-icon>
                <a
                    :href="`https://api.whatsapp.com/send?phone=${user.whatsapp}`"
                    target="_blank"
                    class="text-black iconssocials"
                >
                  <v-icon icon="mdi-whatsapp iconssocials" class="mx-1"></v-icon>
                </a>
                <v-icon
                    icon="mdi-block-helper"
                    @click="dialog = true"
                    class=" iconssocials"
                ></v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <div class="v-col-md-12 " style="padding: 0 !important;">

        <div
            class="bg-blue-grey-lighten-5 w-100 rounded d-flex flex-wrap justify-lg-start justify-center"
        >
          <div
              class="bg-blue-grey-lighten-4 ma-2 pa-2 rounded d-flex flex-column justify-space-between"
              v-for="(prop, i) in addetail.properties"
              style="width: 100px; height: 95px"
              :key="i"
          >
            <img :src="prop.image" style="width: 25px" :alt="prop.name" />
            <p>
              {{ prop.name }}
            </p>
            <div class="px-1" v-for="(ele, i) in prop.values" :key="i">
              <div v-if="typeof ele === 'object'">
                <p>
                  {{
                    ele[0] === "true"
                        ? "نعم"
                        : ele[0] === "false"
                            ? "لا"
                            : ele[0] === "0"
                                ? ele[0]
                                : ele[0]
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
            class="d-flex flex-md-row flex-column justify-space-between align-start my-5"
        >
          <div class="text-h5 v-col-md-7 ">
            <h3 class="mb-2">
              {{ $t("Ad_Details") }}
            </h3>
            <div class="mb-2  desc-text "   v-html="formattedText"></div>


          </div>
          <div class="text-h5  v-col-md-5 left-bar-map " >
            <h3 class="mb-2">
              {{ $t("Location_Details") }}
            </h3>
            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op"> {{ $t("Area") }}: </label>
              <p class=" w-50 text-item-left text-item-op">
                {{ addetail.estate_area }}
              </p>
            </div>
            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op"> {{ $t("City") }}: </label>
              <p class=" w-50 text-item-left text-item-op">
                {{ addetail.city }}
              </p>
            </div>
            <div class="text-grey mb-2 d-flex">
              <label class="w-50 text-item-right text-item-op">  {{ $t("neighborhood") }}: </label>
              <p class=" w-50 text-item-left text-item-op">
                {{ addetail.neighborhood }}
              </p>
            </div>
            <hr class="pas-more"/>

            <v-alert    v-if="addetail.platform"
                style="margin-bottom: 10px"
                color="error"
                :text=" $t('alert_map')"
            ></v-alert>

            <div class="text-grey mb-2 form-map-ad"   v-if="addetail.platform">
                <l-map
                    :zoom="zoomout"
                    :key="mapKey"
                    :options="mapOptions"
                    :center="initialCoordinates"
                >
                  <l-tile-layer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  ></l-tile-layer>

                  <l-marker
                      :lat-lng="[addetail.lat,addetail.lng]"
                  >

                  </l-marker>
                </l-map>
            </div>
          </div>



        </div>
        <TableAd v-if="addetail.platform" :platform="addetail.platform"></TableAd>
      </div>
      <h1>
        {{ $t("Public_services") }}
      </h1>
    </v-container>

    <v-list class="d-flex align-center listtype pa-2">
      <v-list-item
          v-for="(item, i) in serv"
          :key="i"
          @click="providers(item.id)"
          class="text-center"
      >
        <img :src="item.image" style="width: 70px; height: 70px" />
        <!-- <v-img></v-img> -->
        <p style="font-size: 20px" class="font-weight-medium text-center my-2">
          {{ item.name }}
        </p>
      </v-list-item>
    </v-list>
    <v-container>

    </v-container>
  </section>
  <v-container v-else>
    <v-row>
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
            class="mx-auto border-0"
            type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container>
    <h1>
      {{ $t("related_ads") }}
    </h1>
    <v-row >
        <v-col cols="12" md="3" v-for="(ad_item, i) in related_ads" :key="i"  >
          <Card
              :image="ad_item.attachment[0]?ad_item.attachment[0]:''"
              :title="ad_item.price"
              :subtitle="ad_item.estate_type?ad_item.estate_type:ad_item.title + ' ' + ad_item.ad_type?ad_item.ad_type:''"
              :city="ad_item.city"
              :date="ad_item.publish_date?ad_item.publish_date:''"
              :type="ad_item.estate_type?ad_item.estate_type:ad_item.support_service"
              :idcard="ad_item.id"
              :userid="ad_item.user_id"
          ></Card>

        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import crudDataService from '@/Services/crudDataService';
import imgwarn from '../../assets/icon/warning.png';
// Import Swiper styles
import 'swiper/css';
import EasyLightbox from 'vue-easy-lightbox';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
} from "@vue-leaflet/vue-leaflet";
import  TableAd from './TableAds.vue';
import Card from "@/components/Card/Card.vue";

export default {
  components: {
    Card,  LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    Swiper,
    SwiperSlide,
    TableAd,
    EasyLightbox },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => (thumbsSwiper.value = swiper);
    return { thumbsSwiper, setThumbsSwiper, modules: [FreeMode, Navigation, Thumbs] };
  },
  data() {
    return {
      mapOptions: {
        zoomControl: true,
        scrollWheelZoom: true,
        touchZoom: true,
        doubleClickZoom: true,
        boxZoom: true,
      },
      zoomout: 14,
      mapKey: 0,
      currentImageIndex: 1,
      isPictureSwipeOpen: false,
      dialog: false,
      addetail: "",
      items: [],
      related_ads: "",
      serv: [],
      user: "",
      warning: imgwarn,
      texts: [1, 2, 3, 4, 5],
      alert: false,
      initialCoordinates: [24.774265, 46.738586],
      markerCoordinates: [24.774265, 46.738586],
      itemsimage: [

      ]

    };
  },
  computed: {
    formattedText() {
      // استبدال \n\n أو \n بـ <br>
      // return this.addetail.description;
      return this.addetail.description.replace(/\n/g, "<br>");
    }
  },
  methods: {
    isVideo(src) {
      return /\.(mp4|webm|ogg|mov)$/i.test(src);
    },
    closeGallery() {
      this.isPictureSwipeOpen = false;
    },
    openImage(index) {
      this.currentImageIndex = index;
      this.isPictureSwipeOpen = true;

      if (this.$refs.galleryRef) {
        this.$refs.galleryRef.open(index);
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    async blocked(id) {
      await crudDataService.get(`user-block`, id).then((res) => {
        this.dialog = false;
        this.$swal.fire({
          title: res.data.message,
          position: "top-start",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
          customClass: {
            icon: "my-custom-class",
            title: "swal2-title", // Define your custom class here
          },
        });
      });
    },
    providers(id) {
      this.$router.push({ name: "provider", params: { id } });
    },
    async service() {
      let response = await crudDataService.getAll(`services`);
      this.serv = response.data.data;
    },
    gotomap(lang, lat) {
      this.$router.push({
        name: "MapAd",
        params: { param1: `${lang}`, param2: `${lat}` },
      });
    },
    async Singlead() {
      let response = await crudDataService.get(
          `show-ad`,
          `${this.$route.params.id}`
      );
      this.addetail = response.data.data.ad;
      this.related_ads = response.data.data.related_ads;
      response.data.data.ad.attachment.forEach(element => {
        this.initialCoordinates=[this.addetail.lat,this.addetail.lng];
        this.itemsimage.push({
          src: element,
          thumbnail: element,
          w: 300,
          h: 300

        });


      });

      this.user = response.data.data.ad.user;
      // // console.log(this.user);
    },
  },
  mounted() {
    this.service();
    this.Singlead();
  },
};
</script>
<style>
.v-alert.v-theme--light.bg-error.v-alert--density-default.v-alert--variant-flat {
  background-color: #e3dada !important;
  border: 1px solid #d5bdbd94;
}
.v-alert__content {
  color: #8b0000;
  font-size: 13px;
  font-weight: 500;
}
.desc-text {
  max-height: 600px;
  direction: rtl;
  overflow-y: scroll;
  line-height: 15px;
  color: #0e0c1e !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.swiper-slide.swiper-slide-active img {
  width: 100%;
  height: 400px;
}
.form-map-ad .leaflet-container {
  height: 350px !important;
}
.leaflet-control-attribution.leaflet-control{
  display: none !important;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-rtl.main-swiper.swiper-backface-hidden {
  height: 400px;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  color: #a78813;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-free-mode.swiper-rtl.thumb-swiper.swiper-backface-hidden.swiper-thumbs img {
  width: 100%;
  height: 90px;
  border: 1px solid rgb(149 145 129);
}
.swiper-free-mode > .swiper-wrapper {
  margin: 2px auto;
}
.iconssocials{
  background: rgb(246, 199, 18);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: grid;
  text-align: center;
  place-items: center;
  text-decoration: none;
  margin-bottom: 5px;
}

hr.pas-more {
  border: none;
  height: 1px;
  background-color: #c9c9c9;
  margin: 10px 10%;
}
.mor-item {
  padding: 0 35px;
}

.text-item-op{
  margin: 3px auto ;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 600;

}
.text-item-left {
  color: #6a6a6a;
  text-align: end;
}
a.iconssocials {
  margin: 0 15px;
  text-align: center;
}
span.span-price {
  padding: 10px;
  border-radius: 10px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  background-color: #f6c712;
}
.item-price {
  margin-top: 10px;
}
.name-company {
  font-size: 18px !important;
}


.vel-img {
  max-height: 100vh !important;
  max-width: 100% !important;
  width: 100% !important;
}
.left-bar , .left-bar-map{
  margin-top: 10px;
  text-align: center;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #c7c7c7;
  border-top: 0 !important;
  height: 490px;
}
.left-bar-map{
  height: 100% !important;
}

.my-custom-class {
  width: 55px; /* or any size */
  height: 55px; /* or any size */
  transform: scale(0.5);
  margin-top: 0 !important; /* Adjust the scaling factor as needed */
}
.swal2-title {
  display: inline-block; /* Display title inline with the icon */
  vertical-align: middle; /* Align the text vertically in the middle */
  line-height: normal;
  padding-top: 0 !important;
  padding-right: 15px !important;
}
.swal2-popup {
  display: flex !important;
  padding-bottom: 0 !important;
  width: 25em;
  align-items: center;
  height: 55px;
  font-size: 12px;
}
.swiper-slide img {
  border-radius: 15px;
}
.my-gallery{
  display: flex;
  justify-content: center;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-free-mode.swiper-rtl.thumb-swiper.swiper-backface-hidden.swiper-thumbs img {
  width: 95%;

}
figure.gallery-thumbnail{
  width: 100%;
}
.gallery-thumbnail img {
  width: 100%;
  cursor: zoom-in;
  height: 400px;
  border-radius:15px ;
  /*object-fit: cover;*/
}
.v-card-title.mx-auto.mt-2.rounded.pa-2.pt-1.text-h5.font-weight-medium.d-flex.w-100 {
  border-bottom: 1px solid #c9c9c9;
}
.pswp__zoom-wrap{
  position: relative;
  /* Other styling for your content container */
  height: 100%;
}

.pswp__zoom-wrap::after {
  content: '';
  position: absolute;
  top: -60px;
  left: -60%;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/logo.png'); /* Path to your watermark image */
  background-repeat: no-repeat;
  background-position: center; /* Adjust as needed */
  opacity: 0.5; /* Adjust watermark opacity */
  pointer-events: none; /* Allows clicks to pass through */
}
</style>
<style lang="scss" scoped>

.watermarked {
  position: relative;
  /* Other styling for your content container */
  width:max-content ;
}
.v-card-title.pa-0 ,.mdi-map-marker-outline {
  font-size: 17px;
  color: #535353;
}
.watermarked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/logo.png'); /* Path to your watermark image */
  background-repeat: no-repeat;
  background-position: center; /* Adjust as needed */
  opacity: 0.5; /* Adjust watermark opacity */
  pointer-events: none; /* Allows clicks to pass through */
}
i.mdi-check-decagram {
  color: #2ea156;
}
h1,h2 {
  padding-right: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #141835;
  margin-top: 20px;
  margin-bottom: 20px;
}

/**/
.previewads {
  font-size: 14px !important;
}

@media (max-width: 991.98px) {
  .swiper.swiper-initialized.swiper-horizontal.swiper-free-mode.swiper-rtl.thumb-swiper.swiper-backface-hidden.swiper-thumbs img {
    height: 60px;
  }
  .swiper-slide.swiper-slide-active img {
    height: 300px;
  }
  .swiper.swiper-initialized.swiper-horizontal.swiper-rtl.main-swiper.swiper-backface-hidden {
    height: 300px !important;
  }
  .card-head-pac{
    display: block !important;
  }
  .v-col-md-2.v-col-5 img.w-100 {
    width: 50px !important;
  }
  .mor-item.text-start.mt-4 {
    padding: 5px;
  }
  .text-item-op {
    font-size: 13px;
  }
  .div-icon-user {
    margin: 0px 5% !important;
  }
  .company-image {
    padding: 0  !important;
  }
  .company-data {
    padding: 0 !important;
  }
  .name-company {
    font-size: 12px !important;
    font-weight: 600;
  }
  .left-bar, .left-bar-map {
    height: 450px !important;
  }
}

</style>