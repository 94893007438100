<template>
  <ProfileTap :selected="'/chats'"></ProfileTap>
  <v-row>
    <v-col md="4" style="
    height: 500px;
    overflow-y: scroll;
">
      <section v-if="items" class="chats-list">
        <v-row v-for="item in items " :class="{
      active_item_chat: id_chat === item.receiver_id,
      item_chat_not_read: item.count_unread > 0
    }" :key="item.id"   class="align-center " @click="singlechats(item.receiver_id)" style="cursor:pointer;     margin-top: 15px;   border-radius: 15px;
    width: 100%;">

          <v-col cols="2" class="text-center">

            <img :src="item.receiver.image" alt="" class=" rounded-circle" style="width:120px;height:120px">

          </v-col>
          <v-col cols="10">
            <h6 class="font-weight-bold text-h6" >
              {{ item.title? getShortTitle(item.title) :$t('no_name') }}

              <span class="data-message-item">
                {{item.messages[0].date}}
              </span>
            </h6>
            <p class="mb-4 ml-6 text-grey-darken-1 font-weight-medium" style="direction: rtl;
    text-align: right;
    display: contents;">{{ getShorttext(item.messages[0].message) }}
              <span v-if="item.count_unread > 0" class="is_not_read">
                {{item.count_unread}}
              </span>
            </p>
          </v-col>

        </v-row>
      </section>
      <v-row v-else >

        <v-col cols="12" v-for="text in texts" :key="text">
          <v-skeleton-loader
              class="mx-auto border-0 "
              type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="8">
      <section v-if="items_chats" >
        <v-container >
          <div class="image-chat-one">

          </div>
        </v-container>
        <v-container style="height: 400px;overflow: hidden;overflow-y: scroll;">
          <div class=" mb-16">
            <div
                v-for="(item, i) in items_chats"
                :key="item.sender_id"
                class="w-100"
            >
              <div
                  class="mb-7 pa-2 w-100"
              >
                <p
                    class="rounded pa-2"
                    style="background-color: #d9d9d9; width: max-content;    max-width: 100%;"
                    :class="isIdSameAsNext(i)">
                  {{ item.message }}
                </p>

              </div>
            </div>
          </div>
        </v-container>
        <div style="background: #f6c712" class="mt-12 pt-4">
          <v-container>
            <form @submit.prevent="sendMessage()" class="d-flex align-center">
              <v-text-field
                  variant="solo"
                  class="sendmsg"
                  v-model="message"
                  :placeholder="$t('Write_message')"
              ></v-text-field>
              <v-btn
                  type="submit"
                  class="bg-white font-weight-bold text-h6 pb-2"
                  style="width: 150px"
              >
                {{ $t("Send") }}
              </v-btn>
            </form>
          </v-container>
        </div>
      </section>
      <v-container v-else>
        <v-row>
          <v-col cols="12" v-for="text in texts" :key="text">
            <v-skeleton-loader
                class="mx-auto border-0"
                type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>

    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";

export default {
  components: {ProfileTap},
  data() {
    return {
      items: "",
      id_chat: Number(this.$route.params.id),
      items_chats: "",
      texts: [1, 2, 3, 4,5],
      message: "",
    };
  },

  methods: {
    getShortTitle(title) {
      return title.length > 20 ? title.slice(0, 20) + "..." : title;
    },getShorttext(title) {
      return title.length > 20 ? title.slice(0, 70) + "..." : title;
    },
    async getsingle(single_id=this.$route.params.id) {
      this.id_chat=Number(single_id);
      console.log(single_id)
      let response = await crudDataService.get(
          "chats",
          `${single_id}`
      );
      this.items_chats = response.data.data;
      this.items_chats.reverse();
    },
    isIdSameAsNext(index) {

      const currentId = this.items_chats[0].sender_id;
      const nextId = this.items_chats[index].sender_id
      if (currentId === nextId) {
        return   console.log(currentId === nextId);
      }
      return `specialStyle`

    },
    async sendMessage() {
      let res = await crudDataService.create("chats/send-message", {
        receiver_id: `${this.$route.params.id}`,
        message: this.message,
      });
      this.getsingle();
    },
    async getchats() {
      let response = await crudDataService.getAll("chats/index");
      console.log(response.data.data);
      this.items = response.data.data;
    },
    singlechats(id){
      this.$router.push({name:'SingleChat', params:{ id }});
      this.getsingle(id);
    }
  },
  mounted() {
    this.getchats();
  },
};
</script>
<style lang="scss">
.v-row.item_chat_not_read.align-center {
  background-color: rgb(0 0 0 / 9%);
}
section.chats-list {
  margin: 25px 15px;
  border-left: 1px solid #0000002e;
}
span.is_not_read {
  border-radius: 50%;
  background-color: #b70000;
  padding: 0px 7px;
  color: rgb(255 255 255);
  border: 1px solid #a30000;
  float: left;
  font-weight: 600;
}
span.data-message-item {
  float: left;
  font-size: 11px;
  color: #00000082;
  margin-top: -10px;
}
.specialStyle {
  float: left;
  background: #f6c7128c !important;
}
.sendmsg.v-input--density-default .v-field--variant-outlined,
.sendmsg.v-input--density-default .v-field--single-line,
.sendmsg.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.v-row.active_item_chat.align-center {
  border: 1px solid rgb(191 191 191) !important;
  border-radius: 15px;
  width: 100%;
}
img.rounded-circle {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  box-shadow: 0px 0px 0px 2px #0000002b;
}
.check .v-input__details,
.sendmsg .v-input__details {
  display: none !important;
}
.sendmsg.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
