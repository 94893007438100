<template>
  <ProfileTap :selected="'/account-settings/change-password'"></ProfileTap>
  <v-container>
    <section>

      <v-card class="pa-xs-5 px-2 card_update" elevation="0">
        <v-text-field
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        :placeholder="$t('Password')"
        density="compact"
        class="rounded mt-2 mb-3 border-style font-weight-medium"
        variant="solo"
        elevation="0"
        single-line
              hide-details
        @click:append-inner="visible = !visible"
        v-model="changepass.old_password"
      ></v-text-field>
      <v-text-field
        :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible1 ? 'text' : 'password'"
        :placeholder="$t('NewPassword')"
        density="compact"
        class="rounded mt-2 mb-3 border-style font-weight-medium"
        variant="solo"
        elevation="0"
        single-line
              hide-details
        @click:append-inner="visible1 = !visible1"
        v-model="changepass.new_password"
      ></v-text-field>
        <v-text-field
        :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible2 ? 'text' : 'password'"
        density="compact"
        class="rounded mt-2 mb-3 border-style font-weight-medium"
        variant="solo"
        elevation="0"
        single-line
              hide-details
        @click:append-inner="visible2 = !visible2"
        v-model="confirmPassword"
          :placeholder="$t('ConfirmPassword')"
          @input="texterror()"
      ></v-text-field>
    
        <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>
        <div class="mt-md-16 mt-3">
          <button
            class="previewads rounded d-block py-2 mb-3"
            style="width: 65%"
            @click="changepassword"
          >
            {{ $t("Save") }}
          </button>
         
        </div>
      </v-card>
    </section>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";

export default {
  components: {ProfileTap},
  data() {
    return {
      changepass: {
        old_password: "",
        new_password: "",
      },
      confirmPassword: "",
      errorMessage: "",
      visible: false,
      visible1: false,
      visible2: false,
    };
  },
  methods: {
    texterror(){
        if (this.changepass.new_password !== this.confirmPassword) {
        // Handle password mismatch
        this.errorMessage = "New password and confirmation do not match";
      }else{
        this.errorMessage = "";
      }
    },
    async changepassword() {
      await crudDataService
        .create(`change-password`, this.changepass)
        .then((res) => {
          // console.log(res.data.data, "updated");
        })
        .catch((error) => {
          // console.log("lorrr");
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
        });
    },
  },
  mounted() {},
};
</script>
<style>
.border-style .v-field__input::placeholder {
  color: black!important;
  opacity: 1;
  font-weight: 500;
}
</style>
