<template>
  <ProfileTap :selected="'/my-profits'"></ProfileTap>
  <v-row>
    <v-col md="3" style="">
      <section v-if="items" class="profits-list">
        <v-row v-for="item in items " class="align-center  profits-item" :class="{page_active :item.is_active}" @click="getPage(item.route)">
          <div class="content-div">
            <v-icon :icon="item.icon"></v-icon>
            <h6 class="font-weight-bold text-h6" style="margin: auto 10px;">
              {{ item.title }} {{item.icon === "mdi-account-group-outline"? '('+ count_user +')':''}}
            </h6>
          </div>
        </v-row>

      </section>
    </v-col>
    <v-col md="9">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              class="position-relative custominput"
              density="compact"
              :placeholder="$t('Placesearch')"
              single-line
              hide-details
              elevation="0"
              v-model="search"
              @input="userListProfit(1)"
              type="text"
          >
            <div
                class="position-absolute d-flex justify-space-between align-center"
                style="width: 96% !important"
            >
              <v-icon icon="mdi-magnify"> </v-icon>
              <v-icon icon="mdi-filter-outline"></v-icon>
            </div>
          </v-text-field>
        </v-col>
        <table class="table table-data-users" border="1">
          <thead>
          <tr>
            <th>#</th>
            <th>{{$t('Name')}}</th>
            <th>{{$t('status')}}</th>
            <th>{{$t('date_j')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(user, index) in item_users" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.user_name?user.user_name:user.user_phone}}</td>
            <td>
              <span :class="user.status?'class_due':'class_undeserved'">
               {{ user.status?$t('due'):$t('Undeserved')}}
              </span>
            </td>
            <td>{{ user.date }}</td>
          </tr>
          </tbody>
        </table>

        <v-pagination
            class="paginate w-100"
            v-model="page"
            :length="last_page"
            :total-visible="5"
            @update:model-value="userListProfit()"
            v-if="item_users.length > 0"
        ></v-pagination>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import ProfileTap from "@/views/ProfileTap.vue";
import walletIcon from "../../assets/W13.png";
import {useAuth} from "@/axios";


export default {
  components: {ProfileTap},
  data() {
    return {
      walletIcon:walletIcon,
      page: 1,
      items: [
      { title: this.$t("MyProfits"), route: "/my-profits" ,is_active:false,icon:"mdi-wallet"},
      { title: this.$t("users_SUb"), route: "/my-profits/users",is_active:true,icon:"mdi-account-group-outline"},
      { title: this.$t("transactions"), route: "/my-profits/transactions" ,is_active:false,icon:"mdi-cash-multiple"}


    ],
      settings: {
        itemsToShow: 4,
        snapAlign: 'end',
      },
      item_users:'',
      last_page: 0,
      search:'',
      data_profit: "",
      count_user: 0,
      message: "",
    };
  },

  methods: {

    async getPage(page_route) {
      this.$router.push(page_route);
    },
    async userListProfit(page = null) {
      try {
        if(page){
          this.page=page;
        }
        let response=  await crudDataService.getAll("marketers/get-users?page="+ this.page+"&search="+this.search)
        this.page=response.data.data.currentPage;
        this.item_users=response.data.data.users;
        this.last_page=response.data.data.lastPage;
      }catch(error)  {
        // console.log(error.request);
      }

    },
    async userProfit() {
      try {
        let response=  await crudDataService.getAll("marketers/get-profile")
        this.data_profit=response.data.data;
        this.count_user=response.data.data.count_subscription;
        this.text_copied=response.data.data.code;
      }catch(error)  {
        // console.log(error.request);
      }

    }

  },
  mounted() {
    this.userListProfit();
    this.userProfit();
  },
};
</script>
<style lang="scss">
.v-row.item_chat_not_read.align-center {
  background-color: rgb(0 0 0 / 9%);
}
section.chats-list {
  margin: 25px 15px;
  border-left: 1px solid #0000002e;
}
.icon-div {
  width: 100px;
  margin: 15px auto;
  //  margin: auto;
  //  width: max-content;
  //  margin-top: 20px;
  //  font-size: 30px;
  //  padding: 14px 15px;
  //  color: #dfb100;
  //  border-radius: 50%;
  //  border: 2px solid #05105a;

}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {

  padding: 8px;
}

tr:nth-child(even) {
  background-color: rgb(191 191 191 / 4%);
}
table.table.table-data-users {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  border-color: #00000012 !important;
}
table.table.table-data-users th ,table.table.table-data-users td {
  text-align: center;
}
av.v-pagination.v-theme--light.paginate {
  margin: auto;
}
.paginate {
  margin-top: 15px;
  .v-btn--icon .v-icon {
    transform: rotateY(180deg);
  }
}

span.class_undeserved {
  background-color: #b99c4bd6;
  border-radius: 10px;
  padding: 5px 10px;
  color: #ffffff;
}

span.class_due {
  background-color: rgba(86, 185, 75, 0.84);
  border-radius: 10px;
  padding: 5px 10px;
  color: #ffffff;
}
[dir="ltr"] {
  .paginate .v-btn--icon .v-icon {
    transform: none;
  }
}
.item-amount {
  text-align: center;
}
.item-code-text {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border: 1.8px solid #b9b9b9;
  border-radius: 15px;
  color: #0a274a;
}
span.code-share ,span.code-copy {
  float: left;
  padding: 0 10px;
  border-right: 1px solid #b9b9b9;
}

 span.amount-span {
  display: block;
  text-align: center;
  color: #ad8901f5;
  font-size: 19px;
  font-weight: 600;
   direction: rtl;
}
.div-btn-draw {
  margin: 10px auto;
  text-align: center;
}

button.btn-send-draw {
  background-color: #057446f7;
  box-shadow: none;
  color: #fff;
}
.item-code {
  padding-right: 45px;
  margin: auto;
  text-align: center;
  font-weight: 500;
  direction: rtl;
}
.item-code-term {
   margin: 10px;
 }
.pending-amount-div {
  margin-top: 10px;
  text-align: center;
}
span.pending-amount-span {
  padding: 5px;
  border: 1.5px solid;
  color: #a98600;
  border-radius: 5px;
}
i.mdi-exclamation.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default {
  padding: 8px;
  border: 2px solid;
  border-radius: 50%;
  color: #a98600;
  font-size: 20px;
  /* font-weight: 600 !important; */
}
.content-div {
  display: inline-flex;
  align-items: center;
}
span.data-message-item {
  float: left;
  font-size: 11px;
  color: #00000082;
  margin-top: -10px;
}

.trans-head {
  margin: 10px;

}

span.trans-head-title {
  font-weight: 600;
  color: #373740;
}
.item-trans {
  margin: 5px 20px;
  background-color: rgb(0 0 0 / 9%);
  display: inline-flex;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  width: 85%;
}

.item-icon-trans {
  margin: auto;
  padding: 10px;
}
.item-icon-trans {
  margin: auto 10px;
  padding: 5px;
  font-size: 20px;
  color: #317c2b;
  border-radius: 50%;
  border: 2px solid;
}
.item-trans-des {
  width: 80%;
}
.item-trans-des-title {
  font-weight: 500;
  color: #0c1322;
}
.item-trans-des-date {
  float: left;
  color: #00000085;
  font-size: 14px;
}

.item-icon-trans.withdraw {
  color: #b70606 !important;
}

.item-trans.pending_withdraw {
  background-color: #c7000017 !important;
}

.item-trans.pending_deposit {
  background-color: rgb(49 124 43 / 14%) !important;
}

.specialStyle {
  float: left;
  background: #f6c7128c !important;
}

.v-row.align-center.profits-item {
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  width: 80%;
  border: 1px solid rgb(191, 151, 0);
  margin: 15px auto 0px;
  padding: 10px 20px;
}

.v-row.align-center.profits-item.page_active , .v-row.align-center.profits-item:hover {
  background-color: #312f42;
  color: #ddaf00;
}
.sendmsg.v-input--density-default .v-field--variant-outlined,
.sendmsg.v-input--density-default .v-field--single-line,
.sendmsg.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.v-row.active_item_chat.align-center {
  border: 1px solid rgb(191 191 191) !important;
  border-radius: 15px;
  width: 100%;
}
img.rounded-circle {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  box-shadow: 0px 0px 0px 2px #0000002b;
}
.check .v-input__details,
.sendmsg .v-input__details {
  display: none !important;
}
.sendmsg.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
